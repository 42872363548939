<script>
import { useTheme } from '@/composable/Theme/useTheme';
export default {
  name: 'PayButton',

  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    onClick: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    const { fetchButtonSpecs } = useTheme();

    return { fetchButtonSpecs };
  },
};
</script>

<template>
  <ui-button
    v-if="isVisible"
    v-bind="fetchButtonSpecs()"
    data-test-id="pay-button"
    @clickbutton="onClick"
  >
    {{ $t('buttons.pay') }}
  </ui-button>
</template>

