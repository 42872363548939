<script>
import includes from 'lodash/includes';
import { invoicesDownloading } from '@/stores/Invoices/InvoicesMapper';

export default {
  name: 'DownloadPdfButton',

  props: {
    uuid: {
      type: String,
      required: true,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: null,
    },
    onClick: {
      type: Function,
      default: () => {},
    },
  },

  computed: {
    invoicesDownloading,

    isLoading() {
      return includes(this.invoicesDownloading, this.uuid);
    },
  },
};
</script>

<template>
  <ui-button
    v-if="isVisible"
    :face="FACES.text"
    :color="isLoading ? color : GRAYSCALE.ink"
    :square="true"
    :loading="isLoading"
    @clickbutton="onClick"
  >
    <ui-icon
      :color="COLORS.inkLight"
      :icon="ICONS.filePdf"
    />
  </ui-button>
</template>
