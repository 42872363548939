<script>
import { external } from '@emobg/web-api-client';
import FeedbackModalComponent from '@Shared/components/FeedbackModal/FeedbackModalComponent';

import { parseApiErrorMessage } from '@/utils/apiHelpers';
import { genericErrorArgs, genericSuccessArgs } from '@/constants/defaultModalArgs';

import { useTheme } from '@/composable/Theme/useTheme';

export default {
  name: 'PayInvoiceModal',

  components: {
    FeedbackModalComponent,
  },

  props: {
    invoiceUuid: {
      type: String,
      required: true,
    },

    isOpen: {
      type: Boolean,
      default: true,
    },
  },

  setup() {
    const { fetchButtonSpecs } = useTheme();
    return { fetchButtonSpecs };
  },

  data() {
    return {
      isLoading: false,
      modals: {
        active: {
          isOpen: false,
        },
      },
    };
  },

  computed: {
    isModalVisible() {
      return this.isOpen && !this.modals.active.isOpen;
    },
  },

  created() {
    this.successModalArgs = {
      ...genericSuccessArgs(this.$t),
      title: this.$t('modal.confirm_payment.success.title'),
      primaryCallToAction: () => {
        this.modals.active.isOpen = false;
        this.closeModal(true);
      },
      description: this.$t('modal.confirm_payment.success.description'),
    };

    this.errorModalArgs = {
      ...genericErrorArgs(this.$t),
      title: this.$t('modal.confirm_payment.error.title'),
      primaryCallToAction: () => {
        this.modals.active.isOpen = false;
        this.closeModal();
      },
    };
  },

  methods: {
    async confirmPayInvoice() {
      this.isLoading = true;

      try {
        await external.invoicing.putPayInvoice(this.invoiceUuid);
        this.modals.active = this.successModalArgs;
      } catch (error) {
        this.errorMessage = parseApiErrorMessage(this.$t, this.$i18n, error);
        this.modals.active = { ...this.errorModalArgs, description: this.errorMessage };
      }

      this.modals.active.isOpen = true;
      this.isLoading = false;
    },

    closeModal(isPaid) {
      this.$emit('on:close', isPaid);
    },
  },
};
</script>

<template>
  <div>
    <ui-modal
      :open="isModalVisible"
      :header="$t('modal.confirm_payment.title')"
      :size="SIZES.small"
    >
      <div slot="body">
        {{ $t('modal.confirm_payment.subtitle') }}
      </div>
      <div
        slot="footer"
        class="d-flex justify-content-end"
      >
        <ui-button
          v-bind="fetchButtonSpecs({ buttonType: THEME_BUTTON_TYPES.SECONDARY })"
          :disabled="isLoading"
          class="mr-2"
          data-test-id="close-modal"
          @clickbutton="closeModal"
        >
          {{ $t('buttons.cancel') }}
        </ui-button>

        <ui-button
          v-bind="fetchButtonSpecs()"
          :loading="isLoading"
          class="ml-2"
          data-test-id="submit-pay-invoice"
          @clickbutton="confirmPayInvoice"
        >
          {{ $t('buttons.pay') }}
        </ui-button>
      </div>
    </ui-modal>

    <FeedbackModalComponent
      v-model="modals.active.isOpen"
      v-bind="modals.active"
    />
  </div>
</template>
