<script>

export default {
  name: 'MigrationStatementModal',
  props: {
    invoiceDate: {
      type: String,
      required: true,
    },

    invoiceContact: {
      type: String,
      required: true,
    },

    isOpen: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<template>
  <div class="MigrationStatementModal">
    <ui-modal
      :open="isOpen"
      :header="$t('modal.migration_statement.title', {date: invoiceDate})"
    >
      <div slot="body">
        <span v-html="$t('modal.migration_statement.description', {date: invoiceDate, contact: invoiceContact})" />
      </div>

      <div
        slot="footer"
        class="d-flex justify-content-end"
      >
        <ui-button
          :color="COLORS.accent"
          contrast
          @clickbutton="$emit('on:close')"
        >
          {{ $t('buttons.close') }}
        </ui-button>
      </div>
    </ui-modal>
  </div>
</template>
