<script>
import includes from 'lodash/includes';

import { invoicesSelected } from '@/stores/Invoices/InvoicesMapper';

export default {
  name: 'InvoiceCheckComponent',

  props: {
    uuid: {
      type: String,
      required: true,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    onCheck: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isChecked: false,
    };
  },

  computed: {
    invoicesSelected,
  },

  watch: {
    invoicesSelected(currentInvoicesSelected) {
      this.isChecked = includes(currentInvoicesSelected, this.uuid);
    },
  },
};
</script>

<template>
  <ui-checkbox
    v-if="isVisible"
    :checked="isChecked"
    :size="SIZES.small"
    @clickcheckbox="onCheck"
  />
</template>
