import includes from 'lodash/includes';

import { COLORS } from '@emobg/web-components';

import {
  FALLBACK_MESSAGE, TIME_ZONE, formatDate, toBoolean,
} from '@emobg/web-utils';
import { ALGOLIA_REFINEMENT_OPTIONS } from '@emobg/vue-internal';
import { useTranslations } from '@/composable/App/Translations';

import { DATETIME_FORMATS } from '@/constants/datetimeFormats';
import invoiceTypes from '@/constants/invoiceTypes';
import BadgeComponent from '@/components/BadgeComponent/BadgeComponent';

import PayButton from '../PayButton';
import DownloadPdfButton from '../DownloadPdfButton';
import InvoiceReferenceComponent from '../InvoiceReferenceComponent';
import InvoiceCheckComponent from '../InvoiceCheckComponent';

const { $t } = useTranslations();

const isInvoiceOrCancellationType = serieType => includes([invoiceTypes.invoice, invoiceTypes.cancellation], serieType);
const isMigrationType = serieType => invoiceTypes.migration === serieType;

export const downloadedPDFFilename = 'invoice.pdf';
export const downloadedZIPFilename = 'invoices.zip';

export const getTableConfig = (actions, hasChecks) => [
  ...(hasChecks
    ? [
      {
        title: '',
        component: InvoiceCheckComponent,
        props: result => ({
          uuid: result.uuid,
          'data-test-id': 'check',
          isVisible: isInvoiceOrCancellationType(result.serie_type),
          onCheck: () => actions.onCheckInvoice(result.uuid),
        }),
        maxWidth: 50,
      },
    ]
    : []
  ),
  {
    title: $t('invoices.id'),
    component: InvoiceReferenceComponent,
    props: result => ({
      text: `${result.serie}-${result.number}`,
      'data-test-id': 'reference',
      showInfoIcon: result.serie_type === invoiceTypes.migration,
      onClick: () => actions.onClickReference(result.date.date),
      masked: true,
    }),
  },
  {
    title: $t('invoices.date'),
    transformResult: result => (
      isMigrationType(result.serie_type)
        ? `${$t('invoices.before')} ${formatDate(result.date.date, DATETIME_FORMATS.LongMonthYear)}`
        : formatDate(result.date.date, DATETIME_FORMATS.shortDayLongMonthYear)
    ),
    masked: true,
    dataTestId: 'date',
  },
  {
    title: $t('invoices.total'),
    transformResult: result => result.gross_amount_formatted,
    masked: true,
    dataTestId: 'gross_amount',
  },
  {
    title: $t('business_profile.invoice.payment_status'),
    component: BadgeComponent,
    props: result => ({
      ...(result.pending_amount > 0 || result.is_pending_to_pay
        ? {
          text: $t('invoices.status_payment.pending'),
          color: COLORS.danger,
        }
        : {
          text: $t('invoices.status_payment.paid'),
          color: COLORS.success,
        }
      ),
      fontClass: 'emobg-font-line-height-large',
    }),
  },
  {
    title: $t('invoices.pending_payment'),
    transformResult: result => (result.pending_amount ? result.pending_amount_formatted : FALLBACK_MESSAGE.dash),
    masked: true,
    dataTestId: 'pending_payment',
  },
  {
    component: PayButton,
    props: result => ({
      isVisible: result.pending_amount > 0 || result.is_pending_to_pay,
      onClick: () => actions.onClickPay(result.uuid),
    }),
  },
  {
    component: DownloadPdfButton,
    props: result => ({
      uuid: result.uuid,
      'data-test-id': 'invoice-download',
      isVisible: isInvoiceOrCancellationType(result.serie_type),
      onClick: () => actions.onClickDownloadInvoice(result.uuid, `${result.serie_name}-${result.number}`),
    }),
  },
];

export const getFacetsConfigs = (operatorTimezone = TIME_ZONE.default) => [
  {
    type: ALGOLIA_REFINEMENT_OPTIONS.multiSelect,
    props: {
      title: $t('invoices.serie'),
      attributeName: 'serie',
    },
  },
  {
    type: ALGOLIA_REFINEMENT_OPTIONS.multiSelect,
    props: {
      title: $t('invoices.status'),
      attributeName: 'is_pending_to_pay',
      transformValue: value => (toBoolean(value)
        ? $t('invoices.status_payment.pending')
        : $t('invoices.status_payment.paid')),
    },
  },
  {
    type: ALGOLIA_REFINEMENT_OPTIONS.dateRange,
    props: {
      title: $t('invoices.date'),
      attributeName: 'date_ts',
      timezone: operatorTimezone,
    },
  },
];

