<script>
import { CsMask } from '@emobg/vue-base';
export default {
  name: 'InvoiceReferenceComponent',
  directives: {
    CsMask,
  },

  props: {
    text: {
      type: String,
      required: true,
    },
    showInfoIcon: {
      type: Boolean,
      default: false,
    },
    onClick: {
      type: Function,
      default: () => {},
    },
    masked: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<template>
  <div class="d-flex align-items-center">
    <span v-cs-mask="masked">{{ text }}</span>
    <ui-icon
      v-if="showInfoIcon"
      :icon="ICONS.info"
      :size="ICONS_SIZES.medium"
      class="p-2 cursor-pointer"
      @click="onClick"
    />
  </div>
</template>

